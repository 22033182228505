export const routes = [
    {
        path: '/payment_methods',
        name: 'payment_methods.browse',
        component: () => import(/* webpackChunkName: "PaymentMethodsBrowse" */ '@/views/app/PaymentMethodsBrowse.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/payment_methods/create',
        name: 'payment_methods.create',
        component: () => import(/* webpackChunkName: "PaymentMethodsActions" */ '@/views/app/PaymentMethodsActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/payment_methods/:id/edit',
        name: 'payment_methods.edit',
        component: () => import(/* webpackChunkName: "PaymentMethodsActions" */ '@/views/app/PaymentMethodsActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/payment_methods/:id/delete',
        name: 'payment_methods.delete',
        component: () => import(/* webpackChunkName: "PaymentMethodsActions" */ '@/views/app/PaymentMethodsActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup" }
    },
]